import { graphql } from "gatsby";
import { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import StickyProductNav from "../../components/global/StickyProductNav";
import CircleNew from "../../components/home/Circle.New";
import Press from "../../components/home/Press";
import InstagramShowcase from "../../components/InstagramShowcase";
import PageHero from "../../components/PageHero";
import Experts from "../../components/product/Experts";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import Reviews from "../../components/product/Reviews";
import ReviewsHeader from "../../components/reviews/ReviewsHeader";
import PageSEO from "../../components/seo/Page";
import Text from "../../components/Text";
import productSelectors from "../../store/product/selectors";
import metrics from "../../utils/metrics";
import NameMap from "../../utils/nameMap";
import { orderArray } from "../../utils/order";
import { getProductIdForSku } from "../../utils/planToProduct";
import { atLeast, Color, rem, responsive } from "../../utils/style";

// Styled Elements
const CategoryCardsWrapper = styled.div`
  position: relative;
  overflow: hidden;

  h3 {
    font-size: ${rem(24)};
    letter-spacing: -0.28px;
    line-height: ${rem(34)};
    text-align: center;
    margin-bottom: 24px;

    ${responsive.md`
      font-size: ${rem(40)};
      letter-spacing: -1.17px;
      line-height: ${rem(54)};
      margin-bottom: 56px;
    `}
  }
`;

const OrderedCategoryIds = [
  "6rJs8SzkA0sEphJEzuOKfH", // Multi
  "3pskaPDeDeWHJ48CrMJG1I", // Pregnancy
  "1b0toQsOf42guVnMqU1B3z", // Gut Health
  "30ATxQydyyUtAzUy8eCwjR", // Protein
  "5FzlXGv1hVyvpdmHHr8ww6", // Skin
  "3o903h27mp8qfyAhmMQjED", // Stress
  "7xXpND1ui00FfpeKaiqGS4", // Brain & Heart
];

class ReviewsPage extends Component {
  constructor(props) {
    super(props);

    const { pageTitle, pageDescription } = props.data.contentfulReviewsPage;

    const contentfulCategories =
      props.data.allContentfulProductCategory.nodes.filter((node) =>
        OrderedCategoryIds.includes(node.contentful_id),
      );

    const categories = this._getOrderedCategories(contentfulCategories);

    let productsArray = [];
    this.categoryMap = [];

    categories.forEach((category) => {
      const products = this.generateProductsFromCategory(category);
      if (!products.length) return;

      productsArray = productsArray.concat(products);

      this.categoryMap.push({
        name: category.shortName,
        products: products,
      });
    });

    this.state = {
      products: productsArray,
      selectedProductIndex: 0,
      seo: {
        pagePath: "reviews",
        title: pageTitle,
        description: pageDescription,
      },
    };

    this.setAtLeastTablet = this.setAtLeastTablet.bind(this);
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Product Reviews",
    });

    window.addEventListener("resize", this.setAtLeastTablet);
    this.setAtLeastTablet();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setAtLeastTablet);
  }

  _getOrderedCategories(allCategoryNodes) {
    return orderArray(allCategoryNodes, OrderedCategoryIds, "contentful_id");
  }

  generateProductsFromCategory(category) {
    return category.products.reduce((productArray, product) => {
      const id = getProductIdForSku(product.sku);
      const productReviews = this.props.data.allProductReview.nodes.filter(
        (review) => review.product_id === id,
      );

      // Only include reviewed products
      if (productReviews.length > 0) {
        productArray.push({
          ...product,
          id: id,
          categoryName: category.categoryName,
          reviews: productReviews,
          simpleName: NameMap(product.name).plain,
          productUrl: `${process.env.GATSBY_URL}/products/${product.slug}`,
        });
      }
      return productArray;
    }, []);
  }

  changeActiveProduct(index) {
    this.setState({
      selectedProductIndex: index,
    });

    this._trackActiveProductClick(index);
  }

  _trackActiveProductClick(index) {
    let event = {
      option: this.state.products[index].simpleName,
    };

    metrics.track("Product Reviews Selected", event);
  }

  setAtLeastTablet() {
    this.setState({
      atLeastTablet: atLeast.tablet(),
    });
  }

  render() {
    const { products, selectedProductIndex } = this.state;
    const { reviewRatingsMap, reviewCountsMap } = this.props;
    let activeProduct = products[selectedProductIndex];

    let productRating = {
      productId: activeProduct?.id,
      productUrl: activeProduct?.productUrl,
      productName: activeProduct?.simpleName,
      reviewScore: reviewRatingsMap[activeProduct?.id],
      reviewCount: reviewCountsMap[activeProduct?.id],
    };
    const page = this.props.data.contentfulReviewsPage;
    const {
      experts,
      expertsTitle,
      expertsSubhead,
      instagramImages,
      heroTitle,
      heroSubhead,
      press,
    } = page;

    const { heroImageDesktop, heroImageMobile } = this.props.data;

    const heroContent = {
      products,
      heroTitle,
      heroSubhead,
      heroImageDesktop,
      heroImageMobile,
    };

    return (
      <>
        <PageSEO {...this.state.seo} />
        <PageHero
          backgroundColor={Color.ritualYellow}
          imageMobile={heroImageMobile}
          imageDesktop={heroImageDesktop}
          imageObjectPosition="0% 0%"
          className="height-fit"
        >
          <ReviewsHeader {...heroContent} />
        </PageHero>

        {activeProduct?.id && (
          <Reviews
            key={activeProduct?.id}
            reviews={activeProduct?.reviews}
            products={products}
            categoryMap={this.categoryMap}
            selectedProductIndex={selectedProductIndex}
            onProductSelect={this.changeActiveProduct.bind(this)}
            bottomPadding={false}
            {...productRating}
          />
        )}

        <InstagramShowcase images={instagramImages} backgroundColor="#FFFFFF">
          <CircleNew className="bottom left" />
        </InstagramShowcase>

        <Experts
          arrowsEnabled={true}
          expertsTitle={expertsTitle}
          expertsSubhead={expertsSubhead}
          experts={experts}
        >
          <CircleNew className="top left experts" />
        </Experts>

        <Press quotes={press} background={"#e9eef18f"} />

        <CategoryCardsWrapper className="mt-7 mt-md-9 mb-7 mb-md-9">
          <h3>
            <Text
              id="category-card.section.heading"
              defaultMessage="Find Your Ritual"
            />
          </h3>
          <ProductCategoryCards />
        </CategoryCardsWrapper>

        <StickyProductNav
          sku={activeProduct?.sku}
          ctaLocation={"Reviews"}
          scrollOffset={150}
          showLearnMore={true}
          showTopBar={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewCountsMap: productSelectors.reviewCountsMap(state),
  reviewRatingsMap: productSelectors.reviewRatingsMap(state),
});

export default connect(mapStateToProps)(ReviewsPage);

export const pageQuery = graphql`
  query reviewsPageQuery($locale: String!) {
    contentfulReviewsPage(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1gmebXPSIMeyUYsw02qi2o" }
    ) {
      pageTitle
      pageDescription
      heroTitle
      heroSubhead
      expertsTitle
      expertsSubhead
      experts {
        name
        title
        titleSubhead
        externalTitle
        image {
          gatsbyImageData(
            layout: FIXED
            placeholder: DOMINANT_COLOR
            width: 80
            quality: 90
          )
        }
      }
      products {
        sku
      }
      instagramImages {
        title
        description
        gatsbyImageData(
          layout: FIXED
          placeholder: DOMINANT_COLOR
          width: 270
          height: 270
          quality: 90
        )
      }
      press {
        author
        icon
        link
      }
    }
    allContentfulProductCategory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        categoryName
        shortName
        id
        products {
          id
          name {
            name
          }
          slug
          summary
          sku
          stockStatus
        }
      }
    }
    allProductReview {
      nodes {
        id
        product_id
        score
        votes_up
        votes_down
        content
        title
        sentiment
        created_at
        verified_buyer
        sticker
        age_range
        subscription_age
        would_recommend
        received_product
        comment {
          id
          content
          created_at
        }
        user {
          user_id
          display_name
        }
      }
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5q4KEScoheALXp9aG1hu1i" }
    ) {
      description
      id
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 1920
        quality: 90
      )
    }
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2O0LcQDCSvRkyDmf4jTJuy" }
    ) {
      description
      id
      gatsbyImageData(
        layout: CONSTRAINED
        placeholder: DOMINANT_COLOR
        width: 912
        quality: 90
      )
    }
  }
`;
